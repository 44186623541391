import React, { useEffect, useState } from 'react';
import axios from "axios";
import Button from './Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiURL = process.env.REACT_APP_BASE_URL_LIVE;

const Block = ({ isGroupAdded, setIsGroupAdded }) => {
  const [groupName, setGroupName] = useState();

  const handleGroup = () => {
    axios.get(`${apiURL}/api/groups`)
      .then((response) => {
        const existingGroups = response.data.groups;
        if (existingGroups.some(group => group.name === groupName)) {
          toast.error('Group already exists', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        } else {
          axios.post(`${apiURL}/api/create-group`, {
            name: groupName,
            phoneNumbers: [],
          })
            .then((response) => {
              if (response.status === 201) {
                setIsGroupAdded(!isGroupAdded);
                setGroupName('');
                toast.success('Group Created Successfully', {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: 0,
                  theme: "light",
                });
              } else {
                toast.error('Something Went Wrong', {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: 0,
                  theme: "light",
                });
              }
            });
        }
      })
      .catch((error) => {
        toast.error('Error fetching existing groups', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
          theme: "light",
        });
      });
  };

  return (
    <div className='bg-white shadow-md sm:rounded-lg flex justify-end p-2 md:p-4 lg:p-8'>
      <div className="w-full flex justify-between gap-2 items-center">
        <div className="w-3/12 sm:w-2/12">
          <p className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">Group Name</p>
        </div>
        <div className="w-5/12 sm:w-6/12 md:w-7/12 lg:w-8/12">
          <input
            type="text"
            className="group--block--input p-1 md:p-2 rounded"
            value={groupName}
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
          />
        </div>
        <div className="w-3/12 sm:w-2/12">
          <Button disabled={!groupName} icon={'/imgs/plus.png'} onClick={handleGroup} text='Add Group' active hide hideIcon />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Block;