import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from "react-router-dom";
import Button from "./Button";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiURL = process.env.REACT_APP_BASE_URL_LIVE;


const Block = ({ group, setToggler, fromDate, setFromDate, toDate, setToDate, setFilterData, allGroups, setAllGroups, active, setActive }) => {
  const [groupName, setGroupName] = useState();
  const [forwardNumber, setForwardNumber] = useState();
  const [responseTime, setResponseTime] = useState();
  const [chats, setAllChats] = useState(null);
  const [chatWithPhone, setChatWithPhone] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null)
  const [file, setFile] = useState(null)
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [concur, setConcur] = useState();
  const [trunkId, setTrunkId] = useState();

  let { id } = useParams();

  useEffect(() => {
    setSelectedGroupId(id)
    axios.get(`${apiURL}/api/concurrent-number`)
      .then((res) => {
        setConcur(res?.data?.concurrentNumber.con)
      }).catch((err) => {
        console.log(err)
      });

    // axios.get(`${apiURL}/api/getSipSetting`).then((res) => {
    //     setTrunkId(res.data.data.UserName)
    // }).catch((err) => {
    //     console.log("error ::", err)
    // });

    axios.get(`${apiURL}/api/forwarding`).then((response) => {
      // setForwardNumber(response.data.groups);
      setForwardNumber(response.data.forwardingNumbers[0].number);
    }).catch((err) => console.log(err));

    axios.get(`${apiURL}/api/get-response-time`).then((res) => {
      setResponseTime(res.data.getResponse.response_time);
    }).catch((err) => console.log(err));

    axios.get(`${apiURL}/api/get-trunk`)
      .then((res) => {
        // console.log('HELLO::::', res.data.response.trunkId)
        setTrunkId(res.data.response.trunkId)
      }).catch((err) => { console.log(err) })
  }, [])

  // upload excel file

  const handleUploadFile = () => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('excel_file', file);
    formData.append('id', id);

    fetch(`${apiURL}/api/upload-excel`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => console.log(response))
      .then((data) => {
      })
      .catch((error) => {
      });
    toast.success('File updated successfully', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: 0,
      theme: "light",
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };


  const handleGroup = () => {
    axios.get(`${apiURL}/api/groups`)
      .then((response) => {
        const existingGroups = response.data.groups;
        if (existingGroups.some(group => group.name === groupName)) {
          toast.error('Group already exists', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        } else {
          axios.post(`${apiURL}/api/create-group`, {
            name: groupName,
            phoneNumbers: [],
          })
            .then((response) => {
              if (response.status === 201) {
                toast.success('Group Created Successfully', {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: 0,
                  theme: "light",
                });
                setTimeout(() => {
                  window.location.replace(window.location.origin);
                }, 1000);
              } else {
                toast.error('Something Went Wrong', {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: 0,
                  theme: "light",
                });
              }
            })
            .catch((error) => {
              toast.error('Error creating the group', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: 0,
                theme: "light",
              });
            });
        }
      })
      .catch((error) => {
        toast.error('Error fetching existing groups', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
          theme: "light",
        });
      });
  };


  useEffect(() => {
    axios.get(`${apiURL}/api/groups`).then((response) => {
      setAllGroups(response.data.groups);
    });
  }, []);


  const firstData = () => {
    setAllGroups(null)
    // setForwardNumber(null)
    axios.get(`${apiURL}/api/groups`).then((response) => {
      setAllGroups(response.data.groups);
    })
      .catch((err) => console.log(err.message));

  }


  useEffect(() => {
    firstData();

    // axios.get(`${apiURL}/api/concurrent-number`)
    //   .then((res) => {
    //     setConcur(res?.data?.concurrentNumber)
    //   })
    //   .catch((err) => {
    //   })

    // console.log(active);
  }, []);

  // Function to extract the number inside the angle brackets from the clid property
  const extractNumberFromClid = (clid) => {
    const regex = /<(\d{1,})>/;
    const match = clid.match(regex);
    return match && match[1] ? match[1] : null;
  };

  // Update the clid property for each chat object
  const updatedChats = chats?.map((chat) => {
    const extractedNumber = extractNumberFromClid(chat.clid);
    return {
      ...chat,
      clid: extractedNumber ? extractedNumber : chat.clid,
    };
  });

  useEffect(() => {
    setChatWithPhone(updatedChats);
  }, [chats]);


  const handleCalling = () => {
    console.log(active)
    if (active) {
      axios.get(`${apiURL}/api/stop-calling`)
        .then((response) => {
          toast.info('Calls have been ended', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        })
        .catch((err) => {
          toast.error('Something failed! Try again Later', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        })
      setActive(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      // console.log("all group", allGroups)
      setActive(true);
      let phoneNumbers = [];
      allGroups?.filter((itm) => {
        if (selectedGroupId) {
          return itm?._id === selectedGroupId;
        } else {
          return true;
        }
      }).forEach((group, index) => {
        group.phoneNumbers?.forEach((item, subIndex) => {
          // console.log("IN FOREACH",item.number)
          if (item?.number) {
            phoneNumbers.push("96" + item?.number);
            // phoneNumbers.push("98" + item?.number);
          } else {
            phoneNumbers.push("96" + item);
          }
        });
      });


      // calls: "1",
      //     trunk: "PJSIP/me-1003",          
      //     forward: "600923066257058"

      let tempPhone = [
        {
          groupId: id,
          calls: concur,
          trunk: trunkId,
          forward: '6' + forwardNumber,
          response: responseTime,
        },
        phoneNumbers,
      ];

      console.log("tempPhone", tempPhone)
      console.log("grpid", id)

      axios
        .post(`${apiURL}/api/call-numbers`, tempPhone)
        .then((response) => {
          toast.success('Calling Status Changed!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        })
        .catch((err) => {
          setActive(false);
          toast.error('Something failed! Try again with different number', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        })
    }

    console.log(active);
  };

  const handleAddPhoneNumber = () => {

    setSelectedGroupId(id)
    if (!id || !phoneNumber) {
      toast.error('Please provide a phone number.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
        theme: "light",
      })

      return;
    }

    // const newPhoneNumberData = {
    //   groupId: selectedGroupId,
    //   phoneNumber: phoneNumber,
    //   status: "calling", // Example status value
    //   duration: "10s", // Example duration value
    //   keyword: "yes", // Example keyword value
    //   answered: "yes", // Example answered value
    // };

    axios
      .post(`${apiURL}/api/add-phone-number`, {
        groupId: selectedGroupId,
        phoneNumber: phoneNumber,
        status: 'idle',
        duration: 0,
        keyword: '',
        answered: 'NO',
      })
      .then((response) => {
        // console.log(response)
        // console.log('inside add-phone-number', response.data.message);
        firstData()
        if (response.status == 200) {
          toast.success(`${response.data.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        } else {
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        }
      }).catch((err) => {
        toast.error('Something failed! Try again Later', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
          theme: "light",
        });
      });;

    // Clear the input fields after adding the phone number
    // setSelectedGroupId("");
    setPhoneNumber("");
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    localStorage.setItem("toggle", !isChecked);
    setIsChecked(!isChecked);
    setToggler(!isChecked);
  };


  //from and to date filter handling here
  const filterHandler = () => {
    if (fromDate && toDate) {
      const filterResult = group?.phoneNumbers.filter(item => {
        const itemDate = moment(item?.createdAt).format('YYYY/MM/DD');
        return itemDate >= fromDate && itemDate <= toDate;
      });

      setFilterData(filterResult);
    }
  }


  const handleOnUpload = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setFile(null)
      return;
    }

    const fileName = selectedFile.name;
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    if (fileExtension === 'xlsx') {
      setFile(selectedFile);

    } else {
      toast.error('Please select .xlsx file', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
        theme: "light",
      });
      e.target.value = '';
      setFile(null)
    }
  };

  return (
    <div className="bg-white shadow-md sm:rounded-lg flex justify-end p-3 md:p-4 lg:p-8 flex-wrap gap-3" data-testid="groupBlockContainer">

      <div className="w-full flex justify-between gap-2 items-center">
        <div className="w-1/4 sm:w-2/12">
          <p className="text-xs sm:text-sm md:text-base lg:text-lg font-medium truncate">Group Name</p>
        </div>
        <div className="w-5/12 sm:w-8/12">
          <input
            type="text"
            className="group--block--input  p-1 md:p-2 rounded"
            value={groupName}
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
          />
        </div>
        <div className="w-1/4 sm:w-2/12">
          <Button disabled={!groupName} icon={'/imgs/plus.png'} onClick={handleGroup} text='Add Group' active hideIcon />
        </div>
      </div>

      <div className="w-full flex gap-2 items-center">
        {/* <div className="w-2/12">
                    <Button text='Upload Phone .xls' active />
                </div> */}
        {/* <div className="w-6/12">
          <select
            className="group--block--input p-1 md:p-2 rounded"
            value={selectedGroupId}
            onChange={(e) => setSelectedGroupId(e.target.value)}
          >
            <option value="">Select a Group</option>
            {allGroups?.map((group) => (
              <option key={group._id} value={group._id}>
                {group.name}
              </option>
            ))}
          </select>
        </div> */}
        <div className="w-6/12 lg:w-10/12">
          <input
            type="text"
            placeholder="Add phone Number"
            className="group--block--input p-1 md:p-2 rounded"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="w-3/12 ">
          <Button text="Add Phone" onClick={handleAddPhoneNumber} active />
        </div>
        <div className="w-3/12">
          <button
            className={`btn btn-primary flex gap-1 p-1 md:p-2 rounded items-center w-full white-color text-xs sm:text-sm md:text-base lg:text-lg truncate`}
            onClick={handleCalling}
            style={{ background: active ? 'black' : '#256d85' }}
          >
            {active ? 'Stop Calling' : 'Start Calling'}
          </button>
          {/* <button className="btn btn-primary flex gap-1 p-2 rounded items-center   w-full white-color " style={{ background: active ? '#256d85' : 'black' }} onClick={handleCalling}>Start / Stop Calling</button> */}
        </div>
      </div>

      {/* <div className="w-full justify-between flex gap-2 items-center"> */}
      <div className="w-full grid grid-col-2 lg:flex lg:justify-between gap-2 items-center">
        {/* <div className="w-4/12 flex items-center"> */}
        <div className="w-10/12 sm:w-full lg:w-6/12 flex gap-1  justify-between  items-center">
          <p className="font-medium w-2/12 lg:w-3/12 mr-1 md:mr-4 text-xs sm:text-sm md:text-base lg:text-lg truncate " style={{ textWrap: 'nowrap' }}>Upload Excel</p>
          <div className="w-7/12 md:8/12 lg:w-6/12">
            <input
              type="file"
              className="hidden"
              id="file_input"
              accept=".xlsx"
              onChange={handleOnUpload}
            />

            <label
              htmlFor="file_input"
              className="group--block--input flex gap-1 items-center  p-1 md:p-2 rounded border border-gray-300 cursor-pointer hover:bg-gray-100 focus-within:border-blue-500"
            >
              <span className="text-xs sm:text-sm md:text-base lg:text-lg bg--active px-2 text-white rounded truncate" style={{ textWrap: 'nowrap' }}>Choose File</span>
              <span className="text-xs sm:text-sm md:text-base lg:text-lg text-gray-500 truncate" style={{ textWrap: 'nowrap' }}>
                {file ? file.name : 'No file chosen'}
              </span>
            </label>
          </div>
          <div className="w-2/12">
            <button onClick={() => { handleUploadFile() }} disabled={file === 'null'} className="btn btn-primary flex gap-1 p-1 md:p-2 rounded items-center bg--active white-color button--main--color text-xs sm:text-sm md:text-base lg:text-lg justify-self-end ">Upload</button>
          </div>
        </div>


        {/* <div className="w-6/12 flex items-center"> */}
        <div className="w-full lg:w-6/12 flex items-center">
          <div className="w-3/12 md:w-4/12">
            <input
              type="text"
              name="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="group--block--input date--placeholder p-1 md:p-2 rounded"
              placeholder="YYYY/MM/DD"
            />
          </div>
          <div className="w-1/12 md:w-2/12 ml-2 ">
            <DatePicker
              customInput={<img src="/imgs/calendar.svg" className="datePicket--icon" />}
              maxDate={new Date()}
              onChange={(date) => setFromDate(moment(date).format('YYYY/MM/DD'))}
            />
          </div>
          <div className="w-3/12 md:w-4/12">
            <input
              type="text"
              name="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="group--block--input date--placeholder p-1 md:p-2 rounded placeholder-xs"
              placeholder="YYYY/MM/DD"
            />
          </div>
          <div className="w-1/12 md:w-2/12 ml-2" >
            <DatePicker
              customInput={<img src="/imgs/calendar.svg" className="datePicket--icon" />}
              maxDate={new Date()}
              onChange={(date) => {
                setToDate(moment(date).format('YYYY/MM/DD'))
              }}
            />
          </div>
          <div className="w-2/12">
            <Button text="Filter" onClick={filterHandler} active />
          </div>
        </div>

      </div>

      <div className="flex items-center space-x-4">
        <label className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              className="sr-only"
              checked={isChecked}
              onChange={handleToggle}
            />
            <div
              className={`block bg-gray-600 w-10 h-6 rounded-full transition ${isChecked ? "bg-green-500" : "bg-gray-600"
                }`}
            ></div>
            <div
              className={`dot absolute  top-1 w-4 h-4 rounded-full transition ${isChecked ? "bg-white left-5" : "bg-gray-400 left-1"
                }`}
            ></div>
          </div>
          <div className="ml-3 text-gray-700 font-medium">Filter Answered</div>
        </label>
      </div>

      {
        errorMessage && <>
          <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 absolute top-2 left-50" role="alert">
            <span className="font-medium">Danger alert!</span> {errorMessage}
          </div>
        </>
      }
      <ToastContainer />
    </div>
  );
};

export default Block;
