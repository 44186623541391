import React, { useEffect, useState } from "react";
import moment from "moment";
import Modal from "./Modal";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const apiUrl = process.env.REACT_APP_BASE_URL_LIVE;

const TableDetail = ({ group, setGroup, transformedData, setTransfromedData, toggler, filterData, setFilterData, allGroups, setAllGroups }) => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [currentChat, setCurrentChat] = useState();
  const [currentNumber, setCurrNumber] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  // const [additionalInfo, setAdditionalInfo] = useState([]);


  useEffect(() => {

  }, [allGroups, setAllGroups])

  useEffect(() => {
    const fetchChatData = (groupId, currNumber) => {
      // console.log("Inside fetch chat data");
      const data = {
        groupId: groupId,
        number: currNumber, // Make sure you provide the actual number here
      };
      axios
        .post(`${apiUrl}/api/get-chat-text`, data)
        .then((response) => {
          const chatText = response.data.chatLogs;
          // Update the chat data in your component state
          // console.log(chatText, "chatText");
          // setAdditionalInfo(chatText)
          // setChatData(chatText);
          // setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching chat text:", error);
        });
    };

    axios.get(`${apiUrl}/api/group/${id}`).then((response) => {
      setGroup(response.data.group);

      let groups = response.data.group;
      let groupPhones = groups?.phoneNumbers;
      let arr = groupPhones?.map((item) => {
        fetchChatData(id, item?.number);
        return item?.number;
      });
      axios
        .get("https://www.aivoip.org/aivoip/speech/fetch-chat.php")
        .then((response) => {
          if (response.status == 200) {
            let data = response?.data;
            let filtered = data?.map((item) => {
              if (item?.clid) {
                if (arr.includes(item?.clid)) {
                  return item;
                }
              }
            });

            filtered = filtered.filter((item) => item !== undefined);

            const transformedData = filtered?.reduce((result, item) => {
              const { clid, duration, chat, filename } = item;
              if (!result[clid]) {
                result[clid] = {
                  duration,
                  clid,
                  chat: [],
                };
              }
              result[clid].chat.push({ chat, filename });
              return result;
            }, {});

            const transformedArray = Object.values(transformedData);
            setTransfromedData(transformedArray);
          } else {
            toast.error('Unable to fetch teh chat', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: 0,
              theme: "light",
            });
          }
        });
    });

    // const combinedData = filterData?.map(phone => {
    //     const info = additionalInfo.find(item => item.number === phone.number);
    //     // Return a combined object with phone number and additional info
    //     return {
    //       phoneNumber: phone.number,
    //       status: info ? info.call_status : '',
    //       startTime: info ? info.start_time : '',
    //       endTime: info ? info.end_time : '',
    //     };
    //   });

    // console.log(additionalInfo, 'additionalInfo:::::')
    // console.log(combinedData, 'combinedData:::::')

  }, [allGroups]);


  useEffect(() => {
    let result;
    if (toggler) {
      result = group?.phoneNumbers.filter((dt) => dt?.answered === "yes");
    } else {
      result = group?.phoneNumbers
    }
    setFilterData(result);

  }, [toggler, group, allGroups]);


console.log(showConfirmation)
  const handleDelete = () => {
    console.log("handle delete call");
    setShowConfirmation(!showConfirmation);
  };
  const cancelDelete = () => {
    setShowConfirmation(!showConfirmation);
  };    


 let deleteNumber =(id,number)=>{
  console.log(id);
  console.log(number);

    axios.delete(`${apiUrl}/api/delete-phone-number/${id}/${number}`).then((res) => {
     setGroup(res.data.group)
      toast.error('Phone Number deleted successfully!', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: 0,
              theme: "light",
            });
    });

    setShowConfirmation(!showConfirmation);
  }

  return (
    <div>
      <Modal open={open} setOpen={setOpen} currentChat={currentChat} id={id} groupNumber={currentNumber} />
      <div className="py-2 md:py-5 flex gap-5 w-full">
        <h1>Group Name:</h1>
        <p>{group?.name}</p>
      </div>
      <div className="relative overflow-x-auto shadow-md rounded-lg mt-2">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 bg-white">
          <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr key={1}>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3 lg:text-left">
                Phone Number
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Status
              </th>
              <th scope="col" className="text--xxs hidden md:table-cell px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Called Date Time
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Duration
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Keyword
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Detail
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Answered
              </th>
               <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Delete
              </th>
            </tr>
          </thead>
          <tbody class="min-h-64">
            {/* {transformedData?.map((item) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    scope="row"
                    className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <img
                      className="w-10 h-10 rounded-full"
                      src="/imgs/gallery.svg"
                      alt="Jese image"
                    />
                    <div className="pl-3">
                      <div className="text-base font-semibold">{item?.clid}</div>
                    </div>
                  </th>
                  <td className="px-6 py-4 text-center">Calling</td>
                  <td className="px-6 py-4 text-center">1/1/2023 11:00:00</td>
                  <td className="px-6 py-4 text-center">{item?.duration}</td>
                  <td className="px-6 py-4 text-center">yes</td>
                  <td
                    className="px-6 py-4 text-center cursor-pointer"
                    onClick={() => {
                      setCurrentChat(item?.chat);
                      setOpen(true);
                    }}
                  >
                    <img
                      src="/imgs/dd.svg"
                      style={{
                        background: "#256d85",
                        padding: "0.2rem",
                        borderRadius: "0.5rem",
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 text-center">yes</td>
                </tr>
              );

                  {item?.status}
              {info.length > 0 ? info.call_status : item?.status}
            })} */}

            {filterData?.map((item, index) => {
              let call_status = item?.status;
              // if (typeof combinedData !== 'undefined') {
              // console.log(combinedData, "combinedData");
              // const info = combinedData.find(item => item.number);
              // if (typeof info !== 'undefined') {
              //     call_status = info.call_status;
              //     console.log(info.call_status, "info");

              // }
              // }

              return (
                <tr key={filterData?._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    className="align-middle px-3 md:px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="flex items-center justify-center lg:justify-start">
                      <img
                        className="hidden lg:inline-block lg:w-10 lg:h-10 rounded-full"
                        src="/imgs/gallery.svg"
                        alt="Jese image"
                      />
                      <div className="text-xs sm:text-sm md:text-base lg:pl-3 font-semibold ">{item?.number ? item?.number : item}</div>
                    </div>

                  </td>
                  <td className="text-xs sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center">
                    {call_status}
                  </td>
                  <td className="text-xs hidden md:table-cell sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center">{moment(item?.createdAt).format('YYYY/MM/DD')}   {moment(item?.createdAt).format('HH:mm')}</td>
                  <td className="text-xs sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center">{`${item?.duration} Sec`}</td>
                  <td className="text-xs sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center">{item?.keyword}</td>
                  <td
                    className="align-middle cursor-pointer"
                    onClick={() => {
                      setOpen(true);
                      setCurrNumber(item);
                    }}
                  >
                    <div className="flex items-center justify-center">
                      <img
                        src="/imgs/dd.svg"
                        className="md:w-4/12 p-px md:p-1"
                        style={{
                          background: "#256d85",
                          borderRadius: "0.5rem",
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-xs sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center cursor-pointer">
                    {item?.answered}
                    {/*item?.duration?.length > 0 ? "Yes" : "No"*/}
                  </td>
                  <td
                  className="align-middle cursor-pointer"
                  onClick={()=>handleDelete()}
                >
                  <div className="flex items-center justify-center"><img src="/imgs/delete.svg" className="w-6/12" /></div>
                      {showConfirmation && (
                      <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white p-8 ml-[12rem] mt-[8rem] rounded shadow-md">
                          <p className="mb-4">Are you sure you want to delete?</p>
                          <div className="flex justify-center">
                            <button
                            onClick={()=>deleteNumber(id,item?.number)}
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-4"
                            >
                              Yes
                            </button>
                            <button
                            onClick={cancelDelete}
                            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TableDetail;
