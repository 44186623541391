import React, { useEffect, useState } from 'react'
import Card from './Card'
import Navbar from './Navbar'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiURL = process.env.REACT_APP_BASE_URL_LIVE;

const Chart = () => {
  const [messgaes, setMessages] = useState()
  const [isEditResponse, setIsEditResponse] = useState(false);
  const [isEditResponseTime, setIsEditResponseTime] = useState(false);
  const [responseTime, setResponseTime] = useState('');
  const [forwardNumber, setForwardNumber] = useState();


  const responseTimeUpdate = (e) => {
    let newValue = e.target.value;

    newValue = newValue.replace(/^0+/, 0);
    if (newValue !== '') {
      const intValue = parseInt(newValue, 10);

      if (intValue >= 0 && intValue <= 5) {
        setResponseTime(intValue.toString());
      }
    } else {
      setResponseTime(0);
    }
  }

  useEffect(() => {
    axios.get(`${apiURL}/api/messages`)
      .then((res) => {
        // console.log('inside messages api:;::', res.data.messages);
        const sortedMessages = res.data.messages
        sortedMessages.sort((a, b) => a.index - b.index);
        // console.log('sorted api:::', sortedMessages);
        setMessages(sortedMessages)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios.get(`${apiURL}/api/get-response-time`)
      .then((res) => {
        setResponseTime(res.data.getResponse.response_time)

      })
      .catch((err) => {
        console.log(err)

      })
  }, [])

  useEffect(() => {
    axios.get(`${apiURL}/api/forwarding`).then((response) => {
      // setForwardNumber(response.data.groups);
      setForwardNumber(response.data.forwardingNumbers[0].number);
    }).catch((err) => console.log(err));
  }, [])

  const handleOpenEdit = () => {
    setIsEditResponse(true);
  }
  const handleCloseEditModal = () => {
    setIsEditResponse(false)
  }
  const handleCloseEdit = () => {
    setIsEditResponse(false)
      axios.get(`${apiURL}/api/get-response-time`)
      .then((res) => {
        setResponseTime(res.data.getResponse.response_time)

      })
      .catch((err) => {
        console.log(err)

      })
  }

  const handleChangeTime = () => {
    const data = {
      "response_time": responseTime,
    }
    // console.log('clicked')
    axios.post(`${apiURL}/api/response-time`, data).then((res) => {
      setResponseTime(responseTime)
      toast.success('Response time updated successfully', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
        theme: "light",
      });
      // console.log("response :::", res)
    }).catch((err) => {
      toast.error('Encountered an error while updating the response time', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
        theme: "light",
      });
      // console.log("error ::", err)
    });

  }

  return (<>
    <div className='p-3 lg:p-5 mainBg'>
      <Navbar />
      {
        messgaes?.length > 1 && <>
          <Card message={messgaes[0].primary} para={messgaes[0].secondary} index='0' id={messgaes[0]?._id} />
          <div className="flex gap-2 mb-5 items-center ml-2">
            <p className='font-bold text-sm'>Response Time</p>
            <p className='bg-indigo-400 py-1 px-2 rounded flex text-white items-center '>
              {`${responseTime} Sec `}
              <button
                onClick={handleOpenEdit}
                className="icon block text-white font-medium rounded-lg text-sm text-center ml-5"
              >
                <img src="/imgs/editWhite.svg" />
              </button>
            </p>

          </div>

          <Card message={messgaes[1].primary} para={messgaes[1].secondary} index='1' id={messgaes[1]?._id} />

          <div className='flex flex-wrap gap-2 xl:gap-0 ml-5 xl:ml-0'>
            <Card index='2' readonly message={messgaes[2].primary} para={messgaes[2].secondary} id={messgaes[2]?._id} marginLeft='205px' background='#A8C5CE' color='white' />
            <Card index='3' message={messgaes[3].primary} para={messgaes[3].secondary} id={messgaes[3]?._id} marginLeft='20px' background='#A8C5CE' color='white' />
            <Card index='4' disabled readonly message='Transferring the call' para={forwardNumber} marginLeft='20px' background='#A8C5CE' color='white' />
          </div>
          <div className='flex flex-wrap gap-2 xl:gap-0 ml-5 xl:ml-0'>
            <Card index='5' readonly message={messgaes[4].primary} para={messgaes[4].secondary} id={messgaes[4]?._id} background='#72AABD' marginLeft='205px' color='white' />
            <Card index='6' message={messgaes[5].primary} para={messgaes[5].secondary} id={messgaes[5]?._id} background='#72AABD' marginLeft='20px' color='white' />
            <Card index='14' para='Call Ends' marginLeft='20px' background='#72AABD' color='white' readonly disabled />
            {/* <Card index='7' message={messgaes[6].primary} para={messgaes[6].secondary} id={messgaes[6]?._id} background='#0F2C35' marginLeft='20px' color='white' /> */}
          </div>
          <div className='flex flex-wrap gap-2 xl:gap-0 ml-5 xl:ml-0'>
            <Card index='7' readonly message={messgaes[6].primary} para={messgaes[6].secondary} id={messgaes[6]?._id} background='#04364A' marginLeft='205px' color='white' />
            <Card index='8' message={messgaes[7].primary} para={messgaes[7].secondary} id={messgaes[7]?._id} background='#04364A' marginLeft='20px' color='white' />
            <Card index='13' para='Call Ends' marginLeft='20px' background='#04364A' color='white' readonly disabled />
            {/* <Card index='7' message={messgaes[6].primary} para={messgaes[6].secondary} id={messgaes[6]?._id} background='#0F2C35' marginLeft='20px' color='white' /> */}
          </div>
          <p className='ml-5 lg:ml-56'>If Client says Cannot hear you!</p>
          {/* <div className='flex'>
            <Card index='8' message={messgaes[7].primary} para={messgaes[7].secondary} id={messgaes[7]?._id} background='#A8C5CE' marginLeft='205px' color='white' />
            <Card index='9' message={messgaes[8].primary} para={messgaes[8].secondary} id={messgaes[8]?._id} background='#A8C5CE' marginLeft='20px' color='white' />
          </div> */}
          {/* <div className='flex'>
            <Card index='10' message={messgaes[9].primary} para={messgaes[9].secondary} id={messgaes[9]?._id} marginLeft='205px' background='#649F91' color='white' />
          </div> */}
          <div className='flex flex-wrap gap-2 xl:gap-0 ml-5 xl:ml-0'>

            <Card index='9' readonly message={messgaes[8].primary} para={messgaes[8].secondary} id={messgaes[8]?._id} marginLeft='205px' background='#649F91' color='white' />
            <Card index='10' message={messgaes[9].primary} para={messgaes[9].secondary} id={messgaes[9]?._id} marginLeft='20px' background='#649F91' color='white' />
            <Card index='12' para='Call Ends' marginLeft='20px' background='#649F91' color='white' readonly disabled />

          </div>
          <div className='flex ml-5 lg:ml-56 flex'>
            <p>Wait three seconds</p>
          </div>

          <div className='flex flex-wrap gap-2 xl:gap-0 ml-8 sm:ml-56'>
            <Card index='11' message='Voice Response' para='Move back to Sales Pitch' marginLeft='205px' readonly disabled />
          </div>
          {/* <div className='flex ml-5 xl:ml-0'>
            <Card index='12' para='Call Ends' marginLeft='205px' background='#A3A2A2' color='white' readonly disabled />

          </div> */}
        </>
      }
      {isEditResponse && (
        <div
          id="defaultModal"
          className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60"
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-6">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Response Time
            </h3>

            <div>
              <label htmlFor="response">

              </label>
              <span className="text-xs text-red-500">The response time should range from 0 to 5 seconds.</span>
              <input
                type="number"
                id="response"
                className="mb-2 mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={responseTime} max="5" min="0" step="1"
                onChange={responseTimeUpdate}
                required
              />
            </div>

            <button
              onClick={handleCloseEdit}
              className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >
              Close
            </button>

            <button
              onClick={() => {
                handleChangeTime()
                handleCloseEditModal()
              }
              }
              className="ml-3 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              Save
            </button>


          </div>
        </div>
      )}
    </div>
    <ToastContainer />
  </>)
}

export default Chart