import React from 'react'

const Button = ({ text, icon, active, danger, my, onClick, getNumber, disabled, hideTitle, hideIcon }) => {
  return (
    <button getNumber disabled={disabled} onClick={onClick} className={`btn btn-primary flex gap-1 p-1 md:p-2 rounded items-center ${my ? 'my-2' : ''}  w-full ${danger ? 'danger--button' : active ? 'bg--active white-color truncate' : 'button--main--color'} `}>
      {icon && hideIcon ? <img src={icon} className='hidden md:inline-block button--image' /> : <img src={icon} className='button--image' />}
      {(text && hideTitle) ? <p className='hidden md:inline-block text-xs sm:text-sm md:text-base lg:text-lg'>{text}</p> : <p className=' text-xs sm:text-sm md:text-base lg:text-lg'>{text}</p>}
    </button>
  )
}

export default Button