import React from 'react'

const Tabs = () => {
  return (
    <div className='w-32'>
        <p className='mt-10 font-medium'>Active</p>
        <hr />
    </div>
  )
}

export default Tabs