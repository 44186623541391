import React, { useEffect, useState } from "react";
import moment from "moment";
import Chat from "./Chat";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';



const apiUrl = process.env.REACT_APP_BASE_URL_LIVE;

const Table = ({ isGroupAdded }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    axios.get(`${apiUrl}/api/groups`).then((res) => {
      setGroups(res.data.groups);
    });
  }, [isGroupAdded]);

console.log(showConfirmation)
  const handleDelete = () => {
    console.log("handle dlete call");
    setShowConfirmation(!showConfirmation);
  };
  const cancelDelete = () => {
    setShowConfirmation(!showConfirmation);
  };    

  let deleteGroupApi =(id)=>{

    console.log(id);

    axios.delete(`${apiUrl}/api/group/${id}`).then((res) => {
     setGroups(res.data.groups)
      toast.error('Group deleted successfully!', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: 0,
              theme: "light",
            });
   });
    setShowConfirmation(!showConfirmation);

  }

  // console.log(groups);
  return (
    <div>
      <Modal open={open} setOpen={setOpen} />
      <div className="relative overflow-x-auto shadow-md rounded-lg mt-4 md:mt-8">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 bg-white">
          <thead className="text-xs sm:text-sm text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr key={2}>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3 text-left">
                Group Name
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Total Phone No.
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Total Answered
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Total Duration
              </th>
              <th scope="col" className="text--xxs hidden md:table-cell md:px-6 py-1 md:py-3">
                Created Date/Time
              </th>
              <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Edit
              </th>
               <th scope="col" className="text--xxs px-1 sm:px-3 md:px-6 py-1 md:py-3">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {groups?.map((item) => (
              <tr key={groups?._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td
                  className="align-middle  px-3 md:px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <div className="flex items-center justify-start">
                    <img
                      className="hidden lg:inline-block lg:w-10 lg:h-10 rounded-full"
                      src="/imgs/gallery.svg"
                      alt="Jese image"
                    />
                    <div className=" text-xs sm:text-sm md:text-base lg:pl-3 font-semibold ">{item?.name}</div>
                  </div>
                </td>
                <td className="text-xs sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center">{item?.totalPhoneNumbers}</td>
                <td className="text-xs sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center">{item?.totalAnswered}</td>
                <td className="text-xs sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center">{item?.totalDuration != null
                  ? item.totalDuration.includes('.')
                    ? parseFloat(item.totalDuration).toFixed(2) + ' ' + item.totalDuration.split(' ')[1]
                    : item.totalDuration
                  : 'N/A'
                }</td>
                <td className="hidden md:table-cell  text-xs sm:text-sm md:text-base px-3 md:px-6 py-1 md:py-3 lg:py-4 text-center">{moment(item?.createdAt).format('YYYY/MM/DD')}   {moment(item?.createdAt).format('HH:mm')}</td>
                <td
                  className="align-middle cursor-pointer"
                  onClick={() => navigate(`/group-details/${item?._id}`)}
                >
                  <div className="flex items-center justify-center"><img src="/imgs/edit.svg" className="w-6/12" /></div>
                </td>
                <td
                  className="align-middle cursor-pointer"
                  onClick={()=>handleDelete()}
                >
                  <div className="flex items-center justify-center"><img src="/imgs/delete.svg" className="w-6/12" /></div>
                    {showConfirmation && (
                      <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white p-8 ml-[12rem] mt-[8rem] rounded shadow-md">
                          <p className="mb-4">Are you sure you want to delete?</p>
                          <div className="flex justify-center">
                            <button
                            onClick={()=>deleteGroupApi(item?._id)}
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-4"
                            >
                              Yes
                            </button>
                            <button
                            onClick={cancelDelete}
                            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        <ToastContainer />
    </div>
  );
};

export default Table;

//()=>deleteGroupApi(item?._id)
//{handleDelete}
// confirmDelete