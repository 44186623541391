import React from "react";
import Heading from "./Heading";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const icons = [
    "/imgs/logo.svg",
    "/imgs/groupActive.svg",
    "/imgs/group.svg",
    "/imgs/status.svg",
    "/imgs/documentActive.svg",
    "/imgs/document.svg",
    "/imgs/notification.svg",
    "/imgs/logout.svg",
    "/imgs/settingActive.svg",
    "/imgs/setting.svg",
  ];
  const currentPath = window.location.pathname;

  // Extract the string after the last slash (/)
  const pathSegments = currentPath.split("/");
  const lastSegment = pathSegments[pathSegments.length - 2];

  var speech = false;
  var group = false;
  var setting = false;

  // console.log(window.location.origin);

  if (pathSegments.includes("speech")) {
    speech = true;
  }

  if (
    pathSegments.includes("group") ||
    pathSegments.includes("group-details") ||
    window.location.pathname == "/"
  ) {
    group = true;
  }

  if (pathSegments.includes("concurrent-calls")) {
    setting = true;
  }

  return (
    <div className="p-5 flex flex-col justify-between h-screen">
      <div>
        <div className="logo flex items-center gap-2">
          <img src={icons[0]} className="w-8" />
          <h1 className="hidden md:inline-block md:text-xl md:font-semibold ">Sales</h1>
        </div>
        <Heading text={"Main Menu"} />
        <div
          onClick={() => {
            navigate("/");
          }}
        >
          {group ? (
            <Button text="Add Group" icon={icons[1]} active my hideTitle />
          ) : (
            <Button text="Add Group" icon={icons[2]} my hideTitle />
          )}
        </div>
        {/* <div onClick={() => {
          navigate('/group-details')
        }}>
          {
            lastSegment == 'group-details' ? <Button text='Details' icon={icons[2]} active my /> : <Button text='Details' icon={icons[2]} my />
          }
        </div> */}
        <div
          onClick={() => {
            navigate("/speech");
          }}
        >
          {speech ? (
            <Button text="Speech" icon={icons[4]} my active hideTitle />
          ) : (
            <Button text="Speech" icon={icons[5]} my hideTitle />
          )}
        </div>
        {/* <Button text="Notification" icon={icons[4]} my /> */}
        <div
          onClick={() => {
            // navigate("/setting");
            navigate("/concurrent-calls");
          }}
        >
          {setting ? (
            <Button text="Setting" icon={icons[8]} my active hideTitle />
          ) : (
            <Button text="Setting" icon={icons[9]} my hideTitle />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
