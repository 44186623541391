import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiURL = process.env.REACT_APP_BASE_URL_LIVE;


const Card = ({ message, para, lineBottom, marginLeft, background, color, index, id, disabled, readonly }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(message)
  const [currentPara, setCurrentPara] = useState(para)


  const handleOpenEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleUploadAudioFile = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUpload = () => {
    setIsUploadModalOpen(false);
  };


  const handleChangeText = () => {
    let data = { currentMessage, currentPara, index }
    data = { ...data, id: id }
    axios.post(`${apiURL}/api/edit-text-message`, data)
      .then((res) => {
        console.log('Response ::::', res.status)
        if (res.status == 200) {
          // console.log(res.data)
          toast.success('Your data has been saved', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
          // window.location.reload();
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toast.error('Failed to update your message', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
          // console.log('Response ::::', res.status)
        }
      }).catch((err) => {
        toast.error('Nothing to Change.', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
          theme: "light",
        });

        // console.log(err)
      })
  }


  const handleUploadFile = () => {
    if (!file) {
      toast.error('No file selected.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
        theme: "light",
      });
      return;
    }

    const formData = new FormData();
    formData.append('sales_automation_messages', file);
    formData.append('index', index);

    fetch(`${apiURL}/api/upload-file`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.status == 200) {
          toast.success('File uploaded and renamed successfully', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        } else {
          toast.error('Error uploading audio', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error('Error uploading audio:', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: 0,
          theme: "light",
        });
        console.error('Error uploading audio:', error);
      });
    setIsUploadModalOpen(false);
  };


  const handleOnUpload = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setFile(null)
      return;
    }

    const fileName = selectedFile.name;
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    if (fileExtension === 'sln') {
      setFile(selectedFile);

    } else {
      toast.error('Please select .sln file', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
        theme: "light",
      });
      e.target.value = '';
      setFile(null)
    }
  };



  return (
    <div
      className={marginLeft === '205px' ? "w-2/12 card p-5 rounded mb-5 lg:ml-52 relative" :
        (marginLeft === '20px' ? "w-2/12 card p-5 rounded mb-5 ml-0 md:ml-2 xl:ml-4 relative" :
          "w-2/12 card p-5 rounded mb-5 ml-0 relative")}
      style={{ background: background }}
    >
      <div className={lineBottom ? "container" : ""}>
        <p className="card__para text-xs" style={{ color: color }}>
          {message}
        </p>
        <h2 className="card__heading text-sm font-bold h-16" style={{ color: color }}>
          {para}
        </h2>

        <div className="flex justify-end gap-3 absolute right-3 bottom-3">
          {
            !disabled && <>
              <button
                onClick={handleOpenEdit}
                className="icon block text-white font-medium rounded-lg text-sm text-center"
              >
                {color ? <img src="/imgs/editWhite.svg" /> : <img src="/imgs/edit.svg" />}
              </button>
            </>
          }
          {
            !readonly && <>
              <button
                onClick={handleUploadAudioFile}
                className="icon block text-white font-medium rounded-lg text-sm text-center"
              >
                {color ? <img src="/imgs/microphoneWhite.svg" /> : <img src="/imgs/microphone.svg" />}
              </button>
            </>
          }
        </div>
      </div>

      {
        isEditModalOpen && (
          <div
            id="defaultModal"
            className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60"
          >
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-6">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Edit Message
              </h3>

              <div>
                <input
                  type="text"
                  id="first_name"
                  class="mb-2 mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={currentMessage}
                  onChange={(e) => { setCurrentMessage(e.target.value) }}
                  required
                />
                <input
                  type="text"
                  id="first_name"
                  class="mt-2 mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={currentPara}
                  onChange={(e) => { setCurrentPara(e.target.value) }}
                  required
                />
              </div>

              <button
                onClick={handleCloseEdit}
                className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Close
              </button>

              <button
                onClick={() => {
                  handleCloseEdit()
                  handleChangeText()
                }
                }
                className="ml-3 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Save
              </button>


            </div>
          </div>
        )
      }

      {
        isUploadModalOpen && (
          <div
            id="defaultModal"
            className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60"
          >
            {/* Placeholder UI for the upload modal */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-6">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Upload Your Audio File
              </h3>
              <span className="text-xs text-red-500">Only audio files with the .sln extension are allowed to be submitted.</span>

              <input
                class="hidden"
                id="audio_input"
                type="file"
                accept=".sln"
                onChange={handleOnUpload}
              />
              <label
                htmlFor="audio_input"
                className="group--block--input my-4 flex gap-1 items-center  p-2 md:p-3 rounded border border-gray-300 cursor-pointer hover:bg-gray-100 focus-within:border-blue-500"
              >
                <span className="text-xs sm:text-sm md:text-base lg:text-lg bg--active px-2 py-1 text-white rounded truncate" style={{ textWrap: 'nowrap' }}>Choose File</span>
                <span className="text-xs sm:text-sm md:text-base lg:text-lg text-gray-500 truncate" style={{ textWrap: 'nowrap' }}>
                  {file ? file.name : 'No file chosen'}
                </span>
              </label>

              {/* Your upload file UI components can go here */}
              <button
                onClick={handleCloseUpload}
                className="text-white  bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs sm:text-sm md:text-base lg:text-lg px-5 py-2 md:py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Close
              </button>

              <button
                onClick={handleUploadFile}
                disabled={file === 'null'}
                className="ml-3 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs sm:text-sm md:text-base lg:text-lg px-5 py-2 md:py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Upload
              </button>

            </div>
          </div>
        )
      }
      <ToastContainer />
    </div >
  );
};

export default Card;